<template>
  <div class="term-of-use">
    <HeaderCommon ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <TermOfUseCommon />
    </div>
    <template v-if="!$route.query.policy">
      <CardFooter ref="footer" />
    </template>
  </div>
</template>

<script>
import TermOfUseCommon from "@/components/TermOfUseCommon";
import CardFooter from "@/components/CardFooter.vue";
import HeaderCommon from "@/components/CardHeader.vue";

export default {
  name: "TermOfUse",
  components: {
    CardFooter,
    HeaderCommon,
    TermOfUseCommon
  },
  metaInfo() {
    return {
      title: "利用規約・サブスクリプション契約",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      headerTitle: {
        text: "利用規約・サブスクリプション契約",
        isShowLogo: false,
        isShowTitle: true
      }
    };
  },
  mounted() {
    let layout = document.getElementById("layout-default");
    if (localStorage.getItem("step") == 1) {
      layout.style.backgroundImage =
        "url(" + require("@/assets/image/background/bgr-pc.png") + ")";
      let content = document.getElementById("app-container__content");
      // console.log(content);
      content.style.overflow = "auto";
    } else {
      layout.style.backgroundImage =
        "url(" + require("@/assets/image/background/order_list_bg.png") + ")";
    }
  },
  destroyed() {
    let layout = document.getElementById("layout-default");
    layout.style.backgroundImage =
      "url(" + require("@/assets/image/background/order_list_bg.png") + ")";
    if (localStorage.getItem("step") == 1) {
      let content = document.getElementById("app-container__content");
      content.style.overflow = "hidden";
    }
  }
};
</script>

<style lang="scss" scoped>
.content-scroll {
  // overflow-y: scroll;
  padding-top: 42px;
  padding-bottom: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

.term-of-use {
  .app-container {
    height: auto;
    .app-container__content {
      height: auto;
    }
  }
}
@media screen and (min-width: 1200px) {
  .content-scroll {
    // height: calc(100vh - 152px);
    padding-top: 62px;
  }

  .term-of-use {
    .app-container {
      height: 100vh;
      .app-container__content {
        height: 100vh;
      }
    }
  }
}
</style>
